export default function IconMedia() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 10 10"
      id="Image-Picture-Landscape-2--Streamline-Micro"
    >
      <desc>{'Image Picture Landscape 2 Streamline Icon: https://streamlinehq.com'}</desc>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.5 0.5h-7a1 1 0 0 0 -1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1 -1v-7a1 1 0 0 0 -1 -1Z"
        strokeWidth={1}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m2.5 9.5 4.5 -5 2.5 2.333"
        strokeWidth={1}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.25 3.75a0.5 0.5 0 1 0 0 -1 0.5 0.5 0 0 0 0 1Z"
        strokeWidth={1}
      />
    </svg>
  );
}
